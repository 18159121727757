import { graphql, useStaticQuery } from 'gatsby';

import { DEFAULT_EXCLUDED_PAGES, DEFAULT_TITLE_OVERRIDE } from './constants';
import { SiteNode, SitePageQueryResult, TreeItem, UseSiteTreeParams } from './models';
import { createTree } from './utils';

const useSiteTree = ({
  lang = null,
  defaultLang = null,
  exclude = DEFAULT_EXCLUDED_PAGES,
  titleOverride = DEFAULT_TITLE_OVERRIDE,
}: UseSiteTreeParams = {}): TreeItem[] => {
  const { allSitePage }: SitePageQueryResult = useStaticQuery(graphql`
    query AllSitePageQuery {
      allSitePage(
        filter: { context: { enableSearchIndexing: { eq: true } } }
        sort: { fields: context___title, order: ASC }
      ) {
        nodes {
          id
          path
          context {
            title
            locale
          }
        }
      }
    }
  `);

  const nodes: SiteNode[] = allSitePage.nodes
    .map((item) => {
      if (item.context?.locale === null) {
        return {
          ...item,
          context: {
            ...item.context,
            locale: defaultLang,
          },
        };
      }

      return item;
    })
    .filter((item) => {
      if (lang) {
        return lang.includes(item.context?.locale as string);
      }

      return true;
    })
    .map(({ id, path, context }) => ({
      title: context?.title,
      id,
      path,
    }));

  return createTree({
    nodes,
    exclude,
    titleOverride,
  });
};

export default useSiteTree;
