import { ExcludedPath, TitleOverride } from './models';

export const DEFAULT_HOMEPAGE_PATH: string = '/';

export const DEFAULT_TITLE_OVERRIDE: TitleOverride[] = [];

export const DEFAULT_EXCLUDED_PAGES: ExcludedPath[] = [
  '/404/',
  '/404.html',
  '/dev-404-page/',
  '/sitemap/',
];
