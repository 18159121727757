import React, { FC } from 'react';
import useSiteTree from 'hooks/useSiteTree';

import { Container } from 'layout';

import ListTree from './ListTree';
import { IListTreeWrapperProps } from './models';

const ListTreeWrapper: FC<IListTreeWrapperProps> = ({ locale }) => {
  const listOfPages = useSiteTree({ lang: locale });

  return (
    <Container data-testid="ListTreeWrapper">
      <ListTree items={listOfPages} />
    </Container>
  );
};

export default ListTreeWrapper;
